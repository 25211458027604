exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".player_stage-only_3sowo {\n    width: calc(480px + 1rem);\n}\n\n.player_editor_pHkoy {\n    position: absolute;\n    top: 0;\n    left: 0;\n    height: 100%;\n    width: 100%;\n}\n\n.player_stage-only_3sowo * {\n    -webkit-box-sizing: border-box;\n            box-sizing: border-box;\n}\n", ""]);

// exports
exports.locals = {
	"stage-only": "player_stage-only_3sowo",
	"stageOnly": "player_stage-only_3sowo",
	"editor": "player_editor_pHkoy"
};